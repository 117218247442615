import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import {
	maxEmail,
	maxName,
	maxPassword,
	minEmail,
	minName,
	minPassword,
	Regex,
} from "../config/Validators";

export const RegisterRequest = (t: any): RequestProps => ({
	firstname: [
		{
			required: true,
			message: Message(t).required.text(t("labels_first_name")),
		},
		{
			min: minName,
			message: Message(t).min.string(minName, t("labels_first_name")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_first_name")),
		},
	],
	lastname: [
		{
			required: true,
			message: Message(t).required.text(t("labels_last_name")),
		},
		{
			min: minName,
			message: Message(t).min.string(minName, t("labels_last_name")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_last_name")),
		},
	],
	email: [
		{ required: true, message: Message(t).required.text(t("labels_email")) },
		{ type: "email", message: Message(t).email() },
		{
			min: minEmail,
			message: Message(t).min.string(minEmail, t("labels_email")),
		},
		{
			max: maxEmail,
			message: Message(t).max.string(maxEmail, t("labels_email")),
		},
	],
	password: [
		{ required: true, message: Message(t).required.text(t("labels_password")) },
		{
			pattern: Regex.password,
			message: Message(t).regex.password(t("labels_password")),
		},
		{
			min: minPassword,
			message: Message(t).min.string(minPassword, t("labels_password")),
		},
		{
			max: maxPassword,
			message: Message(t).min.string(maxPassword, t("labels_password")),
		},
	],
});

export const LoginRequest = (t: any): RequestProps => ({
	username: [
		{ required: true, message: Message(t).required.text(t("labels_email")) },
		{ type: "email", message: Message(t).email() },
		{
			min: minEmail,
			message: Message(t).min.string(minEmail, t("labels_email")),
		},
		{
			max: maxEmail,
			message: Message(t).max.string(maxEmail, t("labels_email")),
		},
	],
	password: [
		{ required: true, message: Message(t).required.text(t("labels_password")) },
	],
});

export const RecoveryPasswordRequest = (t: any): RequestProps => ({
	email: [
		{ required: true, message: Message(t).required.text(t("labels_email")) },
		{ type: "email", message: Message(t).email() },
		{
			min: minEmail,
			message: Message(t).min.string(minEmail, t("labels_email")),
		},
		{
			max: maxEmail,
			message: Message(t).max.string(maxEmail, t("labels_email")),
		},
	],
});

export const ResetPasswordRequest = (t: any): RequestProps => ({
	password: [
		{ required: true, message: Message(t).required.text(t("labels_password")) },
		{
			min: minPassword,
			message: Message(t).min.string(minPassword, t("labels_password")),
		},
		{
			max: maxPassword,
			message: Message(t).max.string(maxPassword, t("labels_password")),
		},
	],
	confirm_password: [
		{
			required: true,
			message: Message(t).required.text(t("labels_confirm_password")),
		},
		{
			min: minPassword,
			message: Message(t).min.string(minPassword, t("labels_confirm_password")),
		},
		{
			max: maxPassword,
			message: Message(t).max.string(maxPassword, t("labels_confirm_password")),
		},
		({ getFieldValue }) => ({
			validator(_, value) {
				if (!value || getFieldValue("password") === value) {
					return Promise.resolve();
				}
				return Promise.reject(
					new Error(Message(t).same(t("labels_password"), t("labels_confirm_password")))
				);
			},
		}),
	],
});
