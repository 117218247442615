import { RcFile } from "antd/lib/upload";

export const displayFormats = {
	DATE_FORMAT: "DD-MM-YYYY",
	TIME_FORMAT: "HH:mm",
	DATE_TIME_FORMAT: "DD-MM-YYYY HH:mm",
};

export const uploadedFileOnPreview = async (file: any) => {
	let src = file.url as string;
	if (!src) {
		src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		});
	}

	const image = new Image();
	image.src = src;
	const imgWindow: any = window.open(src);
	imgWindow.document.write(image.outerHTML);
};

const insertAt = (array: any[], index: number, ...elementsArray: any[]) => {
	array.splice(index, 0, ...elementsArray);
}

export const convertTextToID = (textArray: any, mainArray: any, textKey = "name", idKey = "id") => {
	const newArray: any = [];
	if (textArray && textArray.values && textArray.values.length > 0) {
		textArray.values.forEach((x: any) => {
			const temp = mainArray.find((y: any) => y[textKey] === x);
			if (x && temp) {
				newArray.push(temp[idKey]);
			} else {
				insertAt(newArray, 0, x);
			}
		});
	}
	
	return newArray;
};
