import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import LogoComponent from "../../Components/LogoComponent";
import Config from "../../../../config/Config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../../store";
// import logoutImg from "../../../../assets/images/logout.svg";

interface AppSidebarViewProps {
	collapsed?: boolean;
}

const SidebarView: React.FC<AppSidebarViewProps> = ({ collapsed }) => {
	const { AUTH } = useStore();
	const { logout, user, userMenu } = AUTH;
	const navigate = useNavigate();
	const [menuItems, setMenuItems] = useState<any>([]);
	const menuItemsTemp = [
		{ key: "/dashboard", url: "/dashboard", label: "Dashboard" },
	];
	const location = useLocation();

	useEffect(() => {
		user && setMenuItems([...menuItemsTemp, ...userMenu]);
	}, [user]);

	const openKey =
		location.pathname === "/metrics" ||
		location.pathname === "/metric-categories" ||
		location.pathname === "/metric-box"
			? ["10019"]
			: [];

	return (
		<Layout.Sider
			collapsed={collapsed}
			width={Config.sidebar_width}
			className="main__page__appsidebar"
		>
			<LogoComponent />
			<div className="sideBarMenu">
				<Menu
					mode="inline"
					theme="dark"
					defaultOpenKeys={openKey}
					items={menuItems}
					selectedKeys={[location.pathname]}
					defaultSelectedKeys={[location.pathname]}
					onClick={(item: any) => navigate(item.key)}
				></Menu>
			</div>
			<div className="sideBottomIcons">
				<Link to="#" onClick={logout}>
					{/* <img src={logoutImg} alt="Logout" /> */}
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							opacity="0.4"
							d="M1.66602 5.37258C1.66602 3.33008 3.35788 1.66675 5.43646 1.66675H9.57072C11.6451 1.66675 13.3327 3.32508 13.3327 5.36425V14.6276C13.3327 16.6709 11.6408 18.3334 9.56139 18.3334H5.42883C3.35364 18.3334 1.66602 16.6751 1.66602 14.6359V13.8526V5.37258Z"
							fill="#676A78"
						/>
						<path
							d="M18.149 9.54579L15.7775 7.12162C15.5324 6.87162 15.138 6.87162 14.8937 7.12329C14.6502 7.37495 14.651 7.78079 14.8953 8.03079L16.1947 9.35828H14.9489H7.95696C7.61203 9.35828 7.33203 9.64579 7.33203 9.99995C7.33203 10.355 7.61203 10.6416 7.95696 10.6416H16.1947L14.8953 11.9691C14.651 12.2191 14.6502 12.625 14.8937 12.8766C15.0162 13.0025 15.1761 13.0658 15.3368 13.0658C15.4959 13.0658 15.6558 13.0025 15.7775 12.8783L18.149 10.455C18.2667 10.3341 18.3333 10.1708 18.3333 9.99995C18.3333 9.82995 18.2667 9.66662 18.149 9.54579Z"
							fill="#676A78"
						/>
					</svg>
					<span>Logout</span>
				</Link>
			</div>
		</Layout.Sider>
	);
};

export default SidebarView;
