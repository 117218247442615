import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { convertTextToID } from "../../config/Global";
import CommonStore from "../CommonStore/CommonStore";
import RootStore from "../RootStore/RootStore";
import { UserListDataProps } from "./MyTeamMembersInterface";

export default class MyTeamMembersStore {
	public list_data?: UserListDataProps[];
	private rootStore: RootStore;
	public details?: any;
	public grid?: any;
	private commonStore: CommonStore;

	constructor(commonStore: CommonStore) {
		this.commonStore = commonStore;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.list_data = value;
	};

	public setDetails = (value?: any): void => {
		this.details = value;
	};

	private setGrid = (value: any): void => {
		this.grid = value;
	};

	// Grid Setup function
	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
	};

	private changeFilterAndSort = (params: any) => {
		if (params.filterModel && params.filterModel["is_active"]) {
			params.filterModel["is_active"].values = convertTextToID(
				params.filterModel["is_active"],
				this.commonStore.statusFilter
			);
		}
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				this.changeFilterAndSort(params.request);
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = async (payload?: any): Promise<any> => {
		return await axios
			.post(API_URL.MY_TEAM_MEMBERS.LIST, payload)
			.then(({ data }) => {
				this.setListData(data.data.rows);
				return data.data;
			});
	};

	public fetchDetails = async (id: number): Promise<any> => {
		return await axios
			.get(API_URL.MY_TEAM_MEMBERS.DETAILS(id))
			.then(({ data }) => {
				this.setDetails(data.data);
			});
	};

	public addMember = async (data: any): Promise<any> => {
		return await axios.post(API_URL.MY_TEAM_MEMBERS.CREATE, data).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public editMember = async (id: number, data: any): Promise<any> => {
		return await axios
			.post(API_URL.MY_TEAM_MEMBERS.UPDATE(id), data)
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};
	public assignClient = async (
		company_id: number[],
		user_id: number,
		client_assign: 1 | 0
	) => {
		return await axios
			.post(API_URL.MY_TEAM_MEMBERS.CLIENT_ASSIGN, {
				company_id,
				user_id,
				client_assign,
			})
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};

	public changeStatus = async (id: number, is_active: number) => {
		return await axios
			.post(API_URL.MY_TEAM_MEMBERS.CHANGE_STATUS(id), { is_active })
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};
}
