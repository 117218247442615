const API_URL = {
	LOGIN: "admin/login",
	ME: "admin/me",
	LOGOUT: "admin/logout",
	FORGOT_PASSWORD: (email: string): string =>
		"/admin/forgot-password?email=" + email,
	RESET_PASSWORD: (otp: string): string => "admin/" + otp + "/reset-password",
	LANGUAGE_DATA: (localKey: string): string => "language/" + localKey,
	USER: {
		LIST: "user/list",
	},
	CLIENT: {
		LIST: "/admin/client-management",
		UPDATE: (id: number): string =>
			"/admin/client-management/" + id + "/update",
		DETAILS: (id: number): string =>
			"/admin/client-management/" + id + "/details",
		CHANGE_STATUS: (id: number, is_active: number): string =>
			"/admin/client-management/" +
			id +
			"/change-status?is_active=" +
			is_active,
		USERS_LIST: (id: number): string => `/admin/client-management/${id}/users`,
		CLIENT_USER_CHANGE_STATUS: (
			userId: number,
			companyId: number,
			is_active: 1 | 0
		): string =>
			`/admin/client-management/${userId}/user-change-status?company_id=${companyId}&is_active=${is_active}`,
	},
	METRICS: {
		LIST: "admin/metrics",
		CREATE: "admin/metrics/create",
		UPDATE: (id: number): string => "admin/metrics/" + id + "/update",
		DELETE: (id: number): string => "admin/metrics/" + id + "/delete",
		DETAILS: (id: number): string => "/admin/metrics/" + id + "/details",
		CHANGE_STATUS: (id: number): string =>
			"/admin/metrics/" + id + "/change-status",
	},
	METRICS_CATEGORY: {
		LIST: "/admin/metric-categories",
		DETAILS: (id: number): string =>
			"/admin/metric-categories/" + id + "/details",
		CREATE: "/admin/metric-categories/create",
		UPDATE: (id: number): string =>
			"/admin/metric-categories/" + id + "/update",
		DELETE: (id: number): string =>
			"/admin/metric-categories/" + id + "/delete",
		ADD_METRIC: (id: number): string =>
			"/admin/metric-categories/" + id + "/add-metric",
		CHANGE_STATUS: (id: number): string =>
			"/admin/metric-categories/" + id + "/change-status",
		REMOVE_FROM_CATEGORY: (id: number): string =>
			`/admin/metric-categories/${id}/remove-category`,
	},
	METRICS_GROUP: {
		LIST: "/admin/metric-group",
		DETAILS: (id: number): string => "/admin/metric-group/" + id + "/details",
		CREATE: "/admin/metric-group/create",
		UPDATE: (id: number): string => "/admin/metric-group/" + id + "/update",
		DELETE: (id: number): string => "/admin/metric-group/" + id + "/delete",
		CHANGE_CATEGORY: (mid: number, id: number): string =>
			"/admin/metric-group/change-category?id=" +
			id +
			"&metric_category_id=" +
			mid,
		CHANGE_STATUS: (id: number): string =>
			"/admin/metric-group/" + id + "/change-status",
		METRIC_LIST: (id: number): string =>
			`/admin/metric-group/${id}/metric-list`,
		REMOVE: `/admin/metric-group/remove-metric`,
		ADD: `/admin/metric-group/add-metric`,
	},
	SUBSCRIPTION: {
		LIST: (search?: string): string =>
			search ? `admin/subscription?search=${search}` : "admin/subscription",
		DETAILS: (id: number): string => "/admin/subscription/" + id + "/details",
		CREATE: "admin/subscription/create",
		UPDATE: (id: number): string => "admin/subscription/" + id + "/update",
		DELETE: (id: number): string => "admin/subscription/" + id + "/delete",
		STATUS: (id: number): string =>
			"admin/subscription/" + id + "/change-status",
	},
	MY_TEAM_MEMBERS: {
		LIST: "admin/team-member",
		DETAILS: (id: number): string => "/admin/team-member/" + id + "/details",
		CREATE: "admin/team-member/create",
		UPDATE: (id: number): string => "admin/team-member/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/team-member/" + id + "/change-status",
		CLIENT_ASSIGN: "/admin/team-member/client-assign",
	},
	All_TRANSACTION: {
		LIST: "trade/transactions/list",
	},
	ROLES: {
		LIST: "admin/roles",
		DETAILS: (id: number): string => "/admin/roles/" + id + "/details",
		UPDATE: (id: number): string => "/admin/roles/" + id + "/update",
		CREATE: "/admin/roles/create",
		DELETE: (id: number): string => "/admin/roles/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
			"/admin/roles/" + id + "/change-status",
	},
	TEMPLATE: {
		LIST: "/admin/template",
		DETAILS: (id: number): string => "/admin/template/" + id + "/details",
		UPDATE: (id: number): string => "/admin/template/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"/admin/template/" + id + "/change-status",
	},
	REFFERALS: {
		LIST: "/admin/referrals",
		DELETE: (id: number): string => `/admin/referrals/${id}/delete`,
		ACCEPT_REFERRAL: `/admin/referrals/accept-referral`,
		CHANGE_STATUS: (id: number, is_active: number): string =>
			`/admin/referrals/${id}/change-status?is_active=${is_active}`,
	},
	COMMON: {
		METRIC_CATEGORY_LIST: "/metric-category-list",
		SYNC: "/sync",
		METRIC_LIST: "/metric-list",
		PRIVILEGES: "/privileges-list",
		COMPANY_LIST: (search?: string): string => {
			if (search) {
				return "/company-list/?search=" + search;
			} else {
				return "/company-list/";
			}
		},
		ROLES_LIST: ({
			search,
			role_type,
		}: {
			search?: string;
			role_type?: 1 | 2;
		}): string =>
			`/roles-list${search ? `?search=${search}` : ""}${
				search && role_type ? "&&" : ""
			}${role_type ? `?role_type=${role_type}` : ""}`,
	},
};

export default API_URL;
