import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { ResetPasswordRequest } from "../../../requests/AuthRequest";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { useNavigate, useParams } from "react-router-dom";

const ResetPasswordViaLink = observer(() => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const { AUTH, ROOT } = useStore();
	const { resetPasswordViaLink } = AUTH;
	const { assignErrorToInput } = ROOT;
	const navigate = useNavigate();
	const [saving, setSaving] = useState(false);
	const { otp }: any = useParams();

	const handleSubmit = (data: any) => {
		setSaving(true);
		resetPasswordViaLink(otp, data.password, data.confirm_password)
			.then(() => {
				setSaving(false);
				navigate("/login");
			})
			.catch((e: any) => {
				setSaving(false);
				assignErrorToInput(form, e.data.errors);
			});
		setSaving(false);
	};

	useEffect(() => {
		//
	}, [otp]);

	return (
		<>
			{otp && (
				<div className="midContainer">
					<section className="section login__section">
						<Card className="width-500">
							<h2 className="card__title">{t("labels_login")}</h2>
							<FormBox
								form={form}
								onFinish={handleSubmit}
								className="logInDetails"
							>
								<InputBox.Password
									name="password"
									addonAfter="Password"
									placeholder=""
									size="large"
									className="passwordField"
									rules={ResetPasswordRequest(t).password}
								/>
								<InputBox.Password
									name="confirm_password"
									addonAfter="Confirm Password"
									placeholder=""
									size="large"
									className="passwordField"
									rules={ResetPasswordRequest(t).confirm_password}
								/>
								<Button
									className="width-150"
									loading={saving}
									type="primary"
									size="large"
									htmlType="submit"
								>
									Reset Password
								</Button>
								<div className="text-center"></div>
							</FormBox>
						</Card>
					</section>
				</div>
			)}
		</>
	);
});

export default ResetPasswordViaLink;
