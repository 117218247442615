import { Card, Button, Form } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InputBox } from "../../../components/AntdAddons";
import FormBox from "../../../components/AntdAddons/components/FormBox";
import { RecoveryPasswordRequest } from "../../../requests/AuthRequest";
import HeaderLogoComponent from "../../layouts/Components/LogoComponent";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import { ExclamationOutlined } from "@ant-design/icons";

const RecoveryPassword = observer(() => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState(false);
	const {
		AUTH: { forgotPassword },
		ROOT: { assignErrorToInput },
	} = useStore();

	const navigate = useNavigate();

	const handleSubmit = (data: any) => {
		setSaving(true);
		forgotPassword(data.email)
			.then(() => {
				navigate("/login");
				setSaving(false);
			})
			.catch(({ data }: any) => {
				assignErrorToInput(form, data.errors);
				setSaving(false);
			});
	};

	return (
		<div className="midContainer">
			<section className="section login__section">
				<Card className="width-500">
					<HeaderLogoComponent />
					<div className="formWrap">
						<h2 className="card__title">Forgot Password</h2>
						<FormBox form={form} onFinish={handleSubmit} className="logInDetails">
						<div className="reminderMsg">
							<i>
								<ExclamationOutlined />
							</i>
							<span>
								Enter your email when registering to which we will send a new
								password
							</span>
						</div>
							<InputBox.Text
								name="email"
								addonAfter={t("labels_email")}
								placeholder=""
								autoFocus={true}
								size="large"
								style={{marginBottom:"10px"}}
								rules={RecoveryPasswordRequest(t).email}
							/>
							<Button
								className="width-150"
								loading={saving}
								type="primary"
								size="large"
								htmlType="submit"
							>
								Send me a new password link
							</Button>
							<div className="text-center">
								<p>
									<Link to="/login">Back</Link>
								</p>
							</div>
						</FormBox>
					</div>
				</Card>
			</section>
		</div>
	);
});

export default RecoveryPassword;
