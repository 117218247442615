export interface MessageProps {
	required: {
		text: (field: string) => string;
		select: (field: string) => string;
	};
	min: {
		string: (min: number, field: string) => string;
		select: (min: number, field: string) => string;
		numeric: (min: number, field: string) => string;
	};
	max: {
		string: (max: number, field: string) => string;
		select: (max: number, field: string) => string;
		numeric: (max: number, field: string) => string;
	};
	email: () => string;
	regex: {
		password: (field: string) => string;
		numeric: () => string;
		decimal: (number: number) => string;
		other: (field: string) => string;
	};
	global: {
		networkIssue: string;
	};
	between: {
		numeric: (field: string, min: any, max: any) => string;
	};
	url: string;
	same: (field: string, other: string) => string;
}

export const Message = (t: any): MessageProps => ({
	required: {
		text: (field) => `${t('validation_required_text', {attribute: field})}`,
		select: (field) => `${t('validation_required_select', {attribute: field})}`,
	},
	min: {
		string: (min, field) => `${t('validation_min_string', {attribute: field, min: min})}`,
		select: (min, field) => `${t('validation_min_select', {attribute: field, min: min})}`,
		numeric: (min, field) => `${t('validation_min_numeric', {attribute: field, min: min})}`,
	},
	max: {
		string: (max, field) => `${t('validation_max_string', {attribute: field, max: max})}`,
		select: (max, field) => `${t('validation_max_select', {attribute: field, max: max})}`,
		numeric: (max, field) => `${t('validation_max_numeric', {attribute: field, max: max})}`,
	},
	email: () => `${t('messages_invalid_email')}`,
	regex: {
		password: (field) => `${t('validation_regex_password', {attribute: field})}`,
		numeric: () => `${t("validation_regex_numeric")}`,
		decimal: (number) => `${t("validation_regex_decimal", {number: number})}`,
		other: (field) => `${t('validation_regex_other', {attribute: field})}`,
	},
	global: {
		networkIssue: `${t('messages_network_issue')}`,
	},
	between: {
		numeric: (field, min, max) => `${t('validation_between_numeric', {attribute: field, min: min, max: max})}`,
	},
	url: `${t('validation_url')}`,
	same: (field, other) => `${t('validation_same', {attribute: field, other: other})}`,
});

export default Message;
