import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { addSubscription, UserListDataProps } from "./SubscriptionInterface";

export default class SubscriptionStore {
	public list_data?: UserListDataProps[];
	public details?: any;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public setListData = (value?: UserListDataProps[]): void => {
		this.list_data = value;
	};

	public setDetails = (details?: any): void => {
		this.details = details;
	};

	// API Functions
	public fetchList = async (search?:string): Promise<any> => {
		return await axios.get(API_URL.SUBSCRIPTION.LIST(search)).then(({ data }) => {
			this.setListData(data.data.count);
		});
	};

	public fetchDetails = async (id: number): Promise<any> => {
		return await axios
			.get(API_URL.SUBSCRIPTION.DETAILS(id))
			.then(({ data }) => {
				this.setDetails(data.data);
			});
	};

	public changeStatus = async (id: number, is_active: 1 | 0) => {
		return await axios
			.post(API_URL.SUBSCRIPTION.STATUS(id), {
				is_active: is_active === 1 ? 0 : 1,
			})
			.then(() => {
				this.fetchList();
			});
	};

	public addSubscription = async (data: addSubscription) => {
		return await axios.post(API_URL.SUBSCRIPTION.CREATE, data).then(() => {
			this.fetchList();
		});
	};

	public updateSubscription = async (data: addSubscription, id: number) => {
		return await axios.post(API_URL.SUBSCRIPTION.UPDATE(id), data).then(() => {
			this.fetchList();
		});
	};

	public deleteSubscription = async (id: number) => {
		return await axios.delete(API_URL.SUBSCRIPTION.DELETE(id)).then(() => {
			this.fetchList();
		});
	};
}
