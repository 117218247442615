import React, { useState } from "react";
import { Button, Card, Form } from "antd";
import { LoginRequest } from "../../../requests/AuthRequest";
import { Link, useNavigate } from "react-router-dom";

import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import HeaderLogoComponent from "../../layouts/Components/LogoComponent";

const Login: React.FC = observer(() => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const navigate = useNavigate();
	const {
		AUTH: { doLogin },
		ROOT: { assignErrorToInput },
	} = useStore();

	const handleSubmit = (data: any) => {
		setSaving(true);

		doLogin(data)
			.then(() => {
				navigate("/dashboard", { replace: true });
			})
			.catch((e: ErrorProps) => {
				assignErrorToInput(form, e?.errors);
				setSaving(false);
			});
	};

	return (
		<div className="midContainer">
			<section className="section login__section">
				<Card className="width-500">
					<HeaderLogoComponent />
					<div className="formWrap">
					<h2 className="card__title">{t("labels_login")}</h2>
					<FormBox form={form} onFinish={handleSubmit} className="logInDetails">
						<InputBox.Text
							name="username"
							// addonAfter={t("labels_email")}
							label={t("labels_email")}
							autoFocus={true}
							size="large"
							rules={LoginRequest(t).username}
						/>
						<InputBox.Password
							name="password"
							// addonAfter="Password"
							label="Password"
							// placeholder="password"
							size="large"
							className="passwordField"
							rules={LoginRequest(t).password}
							extra={[
								<Link
									key={"recovery"}
									style={{ border: "0px", display: "inline" }}
									to={"/recover-password"}
								>
									Recovery Password
								</Link>,
							]}
						/>
						<Button
							className="width-150"
							loading={saving}
							type="primary"
							size="large"
							htmlType="submit"
						>
							Login
						</Button>
						<div className="text-center">
						</div>
					</FormBox>
					</div>
				</Card>
			</section>
		</div>
	);
});

export default Login;
