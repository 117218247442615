import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { UserListDataProps } from "./TemplateInterface";

export default class TemplateStore {
	public listData?: UserListDataProps[];
	public details?: any;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.listData = value;
	};

	public setDetails = (details: any): void => {
		this.details = details;
	};

	// API Functions
	public fetchList = async (search?: string): Promise<any> => {
		return await axios
			.post(API_URL.TEMPLATE.LIST, { type: "email", name: search })
			.then(({ data }) => {
				this.setListData(data.data.rows);
			});
	};

	public fetchDetails = async (id: number): Promise<any> => {
		return await axios.get(API_URL.TEMPLATE.DETAILS(id)).then(({ data }) => {
			this.setDetails(data.data);
		});
	};

	public updateEmailTemplate = async (id: number, template_url: string) => {
		return await axios
			.post(API_URL.TEMPLATE.UPDATE(id), {
				template_url: template_url,
			})
			.then(() => this.fetchList());
	};

	public changeStatus = async (id: number, is_active: number) => {
		return await axios.post(API_URL.TEMPLATE.CHANGE_STATUS(id), { is_active });
	};
}
