import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";

// export type MetricList = {
// 	type?: string;
// 	metric_category_id?: string;
// 	search?: string;
// };

export type ReferralStatusProps = {
	id:number,
	name:string
}[]

export default class CommonStore {
	private rootStore: RootStore;
	public translationData?: any;
	public selectedLanguage: any;
	public rolesList?: any[];
	public rolesTypeList?: any[];
	public metricCategoryList?: any;
	public metricFormatList?: any;
	public metricList?: any[];
	public privilegesList?: any[];
	public companyList?: any[];
	public statusFilter?: string[];
	public referralStatusList?: { id: number; name: string }[];

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	public setTranslationData = (value?: any): void => {
		this.translationData = value;
	};

	public setSelectedLanguage = (selectedLanguage: any) => {
		this.selectedLanguage = selectedLanguage;
	};

	public setRolesList = (roles: any) => {
		this.rolesList = roles;
	};

	public setRoleTypeList = (roles: any) => {
		this.rolesTypeList = roles;
	};

	public setMetricCategoryList = (categoryList: any) => {
		this.metricCategoryList = categoryList;
	};

	public setMetricFormatList = (metricFormats: any) => {
		this.metricFormatList = metricFormats;
	};

	public setMetricList = (metrics: any[]) => {
		this.metricList = metrics;
	};

	public setPrivilegesList = (privileges: any[]) => {
		this.privilegesList = privileges;
	};

	public setCompanyList = (companies: any) => {
		this.companyList = companies;
	};

	private setStatusFilter = (value?: any) => {
		this.statusFilter = value;
	};

	private setReferralStatusList = (data:ReferralStatusProps)=>{
		this.referralStatusList = data
	}

	// API Functions
	public loadTranslationData = async (locale: string) => {
		return await axios.get(API_URL.LANGUAGE_DATA(locale)).then(({ data }) => {
			this.setTranslationData(data);
			return data;
		});
	};

	public fetchRolesList = async (search?: string, role_type?: 1 | 2) => {
		const params = {
			search: search,
			role_type: role_type,
		};
		return await axios
			.get(API_URL.COMMON.ROLES_LIST(params))
			.then(({ data }) => {
				this.setRolesList(data.data);
				return data.data;
			});
	};

	public fetchMetricCategoryList = async (search?: string) => {
		const params = { search: search, access_from: 1 };
		return await axios
			.get(API_URL.COMMON.METRIC_CATEGORY_LIST, { params })
			.then(({ data }) => {
				this.setMetricCategoryList(data.data);
				return data.data;
			});
	};

	public fetchSync = async () => {
		return await axios.post(API_URL.COMMON.SYNC).then(({ data }) => {
			this.setMetricFormatList(data.data.formatOfMatrix);
			this.setStatusFilter(data.data.status);
			this.setRoleTypeList(data.data.roleType);
			this.setReferralStatusList(data.data.referralStatus);
			return data.data;
		});
	};

	public fetchMetricList = async (payload: any) => {
		return await axios
			.get(API_URL.COMMON.METRIC_LIST, { params: payload })
			.then(({ data }) => {
				this.setMetricList(data.data.rows);
				return data.data;
			});
	};

	public fetchPrivilegesList = async (menuType = "0") => {
		return await axios
			.get(API_URL.COMMON.PRIVILEGES, { params: { menu_type: menuType } })
			.then(({ data }) => {
				this.setPrivilegesList(data.data);
				return data.data;
			});
	};

	public fetchCompanyList = async (search?: string) => {
		return await axios
			.get(API_URL.COMMON.COMPANY_LIST(search))
			.then(({ data }) => {
				this.setCompanyList(data.data);
				return data.data;
			});
	};
}
