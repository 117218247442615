import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { UserListDataProps } from "./RolesInterface";

export default class RolesStore {
	public listData?: UserListDataProps[];
	public details?: any;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.listData = value;
	};

	public setDetails = (details?: any) => {
		this.details = details;
	};

	// API Functions
	public fetchList = async (): Promise<any> => {
		return await axios.get(API_URL.ROLES.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
		});
	};

	public fetchDetails = async (id: number): Promise<any> => {
		return await axios.get(API_URL.ROLES.DETAILS(id)).then(({ data }) => {
			this.setDetails(data.data);
		});
	};

	public addMember = async (data: any): Promise<any> => {
		return await axios.post(API_URL.ROLES.CREATE, data).then(() => {
			this.fetchList();
		});
	};

	public editMember = async (id: number, data: any): Promise<any> => {
		return await axios.post(API_URL.ROLES.UPDATE(id), data).then(() => {
			this.fetchList();
		});
	};

	public deleteMember = async (id: number) => {
		return await axios.delete(API_URL.ROLES.DELETE(id)).then(this.fetchList);
	};

	public changeStatus = async (id: number, is_active: number) => {
		return await axios
			.post(API_URL.ROLES.CHANGE_STATUS(id), { is_active })
			.then(this.fetchList);
	};
}
