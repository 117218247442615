import { createContext, useContext } from "react";
import { Context } from "vm";
import AllTransactionStore from "./AllTransactionStore/AllTransactionStore";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import UserStore from "./UserStore/UserStore";
import MetricsStore from "./MetricsStore/MetricsStore";
import SubscriptionStore from "./SubscriptionStore/SubscriptionStore";
import MyTeamMembersStore from "./MyTeamMembersStore/MyTeamMembersStore";
import CommonStore from "./CommonStore/CommonStore";
import MetricCategoryStore from "./MetricsCategoryStore/MetricsCategoryStore";
import MetricGroupStore from "./MetricGroupBox/MetricGroupStore";
import RolesStore from "./RoleStore/RoleStore";
import ClientStore from "./ClientsStore/ClientsStore";
import TemplateStore from "./TemplateStore/TemplateStore";
import ReferralsStore from "./RefferalsStore/RefferalStore";

const commonStore = new CommonStore();
const AppContext = createContext({
	ROOT: new RootStore(),
	AUTH: new AuthStore(),
	USER: new UserStore(),
	ROLES: new RolesStore(),
	ALL_TRANSACTIONS: new AllTransactionStore(),
	METRICS: new MetricsStore(commonStore),
	METRICS_CATEGORY: new MetricCategoryStore(commonStore),
	METRICS_GROUP: new MetricGroupStore(commonStore),
	SUBSCRIPTION: new SubscriptionStore(),
	MY_TEAM_MEMBERS: new MyTeamMembersStore(commonStore),
	CLIENTS: new ClientStore(commonStore),
	TEMPLATE: new TemplateStore(),
	REFERRALS: new ReferralsStore(),
	COMMON: commonStore,
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
