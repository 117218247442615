import React from "react";
import { useNavigate } from "react-router-dom";
import LOGO_IMAGE from "../../../assets/images/Logo.svg";

const LogoComponent: React.FC = () => {
	const navigate = useNavigate()
	return (
		<div className="logo__wrapper" onClick={()=>{navigate("/dashboard")}}>
			<img src={LOGO_IMAGE} alt="LOGO" />
		</div>
	);
};

export default LogoComponent;
