import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { convertTextToID } from "../../config/Global";
import CommonStore from "../CommonStore/CommonStore";
import RootStore from "../RootStore/RootStore";
import { MetricCategory } from "./MetricsCategoryInterface";

export type AddData = {
	metric_id?: number[];
	name: string;
};

export default class MetricCategoryStore {
	public metricCategoryList?: MetricCategory[];
	public metricCategoryDetails?: any;
	private rootStore: RootStore;
	private commonStore: CommonStore;
	public grid?: any;

	constructor(commonStore: CommonStore) {
		this.commonStore = commonStore;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter function
	public setListData = (list: any) => {
		this.metricCategoryList = list;
	};

	public setMetricCategoryDetails = (value?: any) => {
		this.metricCategoryDetails = value;
	};

	private setGrid = (value?: any) => {
		this.grid = value;
	};

	// Grid Setup Functions

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
	};

	public changeFilterAndSort = (params: any) => {
		if (params.filterModel && params.filterModel["is_active"]) {
			params.filterModel["is_active"].values = convertTextToID(
				params.filterModel["is_active"],
				this.commonStore.statusFilter
			);
		}
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				this.changeFilterAndSort(params.request)
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data: any) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API functions
	public fetchList = async (payload?: any) => {
		return await axios
			.post(API_URL.METRICS_CATEGORY.LIST, payload)
			.then(({ data }) => {
				this.setListData(data.data.rows);
				return data.data;
			});
	};

	public fetchMetricCategoryDetails = async (id: number) => {
		return await axios
			.get(API_URL.METRICS_CATEGORY.DETAILS(id))
			.then(({ data }) => {
				this.setMetricCategoryDetails(data.data);
			});
	};

	public addMetricCategory = async (data: AddData) => {
		return await axios.post(API_URL.METRICS_CATEGORY.CREATE, data).then((response) => {
			this.grid && this.setupGrid(this.grid);
			return response.data;
		});
	};

	public updateMetricCategory = async (data: AddData, id: number) => {
		return await axios
			.post(API_URL.METRICS_CATEGORY.UPDATE(id), data)
			.then((response) => {
				this.grid && this.setupGrid(this.grid);
				return response.data;
			});
	};

	public deleteMetricCategory = async (id: number) => {
		return await axios.delete(API_URL.METRICS_CATEGORY.DELETE(id)).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public removeFromCategory = async (metric_id: number[], id: number) => {
		return await axios.post(API_URL.METRICS_CATEGORY.REMOVE_FROM_CATEGORY(id), {
			metric_id: metric_id,
		}).then((data: any) => {
			return data.data;
		});
	};

	public addMetricToCategory = async (metric_id: number[], id: number) => {
		return await axios
			.post(API_URL.METRICS_CATEGORY.ADD_METRIC(id), {
				metric_id: metric_id,
			})
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};

	public changeStatus = async (id: number, is_active: number) => {
		return await axios
			.post(API_URL.METRICS_CATEGORY.CHANGE_STATUS(id), { is_active })
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};
}
