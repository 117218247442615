import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { convertTextToID } from "../../config/Global";
import CommonStore from "../CommonStore/CommonStore";
import RootStore from "../RootStore/RootStore";
import { UserListDataProps } from "./ClientsInterface";

export default class ClientStore {
	public grid?: GridReadyEvent;
	private rootStore: RootStore;
	private commonStore: CommonStore;
	public listData?: UserListDataProps[];
	public clientDetails?: UserListDataProps;
	public clientUserGrid?: GridReadyEvent;
	public clientUserListData?: any;
	public clientIdList = 0;
	public clientUserClientDetails?: any;

	constructor(commonStore: CommonStore) {
		this.rootStore = new RootStore();
		this.commonStore = commonStore;
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.listData = value;
	};

	private setClientDetails = (value?: UserListDataProps): void => {
		this.clientDetails = value;
	};

	private setGrid = (value: any) => {
		this.grid = value;
	};

	private setClientUserListData = (value?: any): void => {
		this.clientUserListData = value;
	};

	public setClientIdList = (value: any) => {
		this.clientIdList = value;
	};

	private setClientUserGrid = (value: any) => {
		this.clientUserGrid = value;
	};

	public setClientUserClientDetails = (value: any) => {
		this.clientUserClientDetails = value;
	};

	// Grid Setup Functions

	public setupGrid = (params?: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params && params.api.setServerSideDatasource(dataSource);
	};

	public changeFilterAndSort = (params: any) => {
		// Filter
		if (params.filterModel && params.filterModel["is_active"]) {
			params.filterModel["is_active"].values = convertTextToID(
				params.filterModel["is_active"],
				this.commonStore.statusFilter
			);
		}
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				this.changeFilterAndSort(params.request);

				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = async (payload: any): Promise<any> => {
		return await axios.post(API_URL.CLIENT.LIST, payload).then(({ data }) => {
			this.setListData(data.data);
			return data.data;
		});
	};

	public fetchClientDetails = async (id: number): Promise<any> => {
		return await axios.get(API_URL.CLIENT.DETAILS(id)).then(({ data }) => {
			this.setClientDetails(data.data);
		});
	};

	public updateClient = async (data: any, id: number): Promise<any> => {
		return await axios.post(API_URL.CLIENT.UPDATE(id), data).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public changeStatus = async (id: number, is_active: number): Promise<any> => {
		return await axios
			.post(API_URL.CLIENT.CHANGE_STATUS(id, is_active))
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};

	// Grid Setup Functions
	public setupClientUserGrid = (params?: GridReadyEvent) => {
		this.setClientUserGrid(params);
		const dataSource = this.createDataSourceClientUser(
			Config.grid.server.gridOptions
		);
		params && params.api.setServerSideDatasource(dataSource);
	};

	// public changeFilterAndSort = (params: any) => {
	//     // Filter
	//     if (params.filterModel && params.filterModel["is_active"]) {
	//         params.filterModel["is_active"].values = convertTextToID(params.filterModel["is_active"], this.commonStore.statusFilter);
	//     }
	// };

	private createDataSourceClientUser = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				// this.changeFilterAndSort(params.request);

				const payload = this.rootStore.getServerListPayload(params);
				this.fetchClientUserList(payload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchClientUserList = async (payload: any): Promise<any> => {
		return await axios
			.post(API_URL.CLIENT.USERS_LIST(this.clientIdList), payload)
			.then(({ data }) => {
				this.setClientUserListData(data.data.rows);
				this.setClientUserClientDetails(data.data.companyDetails);
				return data.data;
			});
	};

	public changeClientUserStatus = async (
		userId: number,
		companyId: number,
		is_active: 1 | 0
	): Promise<any> => {
		return await axios
			.post(
				API_URL.CLIENT.CLIENT_USER_CHANGE_STATUS(userId, companyId, is_active)
			)
			.then(() => {
				return this.setupClientUserGrid(this.clientUserGrid);
			});
	};
}
