import { makeAutoObservable } from "mobx";
import axios from "axios";
import { loginType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";

export default class AuthStore {
	// Variables
	public user?: any;
	public token?: string;
	public app_loading: boolean;
	public email?: string;
	private rootStore: RootStore;
	public userMenu?: any;
	private userPrivileges: any;

	constructor() {
		this.app_loading = true;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Initiate Application Functions
	public InitializeApp = () => {
		this.rootStore.setAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.rootStore.setNotificationConfig();
		this.setupHeaders(localStorage.getItem("token") ?? "");
	};

	public setupHeaders = (accessToken?: string) => {
		this.setAppLoading(true);
		this.rootStore
			.setAxiosHeaders(accessToken)
			.then((token) => {
				this.setToken(token);
				this.fetchAuthUser();
			})
			.catch(() => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				this.setAppLoading(false);
			});
	};

	// Setter Functions
	public setUser = (value?: any): void => {
		this.user = value;
	};
	public setUserPrivileges = (value?: any): void => {
		this.userPrivileges = value;
	};
	public setToken = (value?: string): void => {
		this.token = value;
	};
	public setAppLoading = (value: boolean): void => {
		this.app_loading = value;
	};
	public setEmail = (value: string): void => {
		this.email = value;
	};
	public setUserMenu = (value: any): void => {
		this.userMenu = value;
	};

	// API Functions
	public doLogin = async (payload: loginType): Promise<any> => {
		return await axios
			.post(API_URL.LOGIN, payload)
			.then(({ data }) => {
				this.setupHeaders(data.data.token);
				return data;
			})
			.catch(({ data }) => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};

	public fetchAuthUser = (): Promise<any> => {
		return axios
			.get(API_URL.ME)
			.then(({ data }) => {
				this.setUserMenu(data.data.menu);
				this.setUser(data.data);
				this.setUserPrivileges(data.data.userPrivileges);
				return data;
			})
			.catch(({ data }) => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};

	public logout = async (): Promise<any> => {
		return await axios
			.get(API_URL.LOGOUT)
			.then(({ data }) => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public forgotPassword = async (email: string) => {
		return await axios.post(API_URL.FORGOT_PASSWORD(email)).then(({ data }) => {
			return data;
		});
	};

	public resetPasswordViaLink = async (
		otp: string,
		password: string,
		confirmPassword: string
	) => {
		return await axios.post(API_URL.RESET_PASSWORD(otp), {
			password: password,
			password_confirmation: confirmPassword,
		});
	};
}
