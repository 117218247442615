import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { convertTextToID } from "../../config/Global";
import CommonStore from "../CommonStore/CommonStore";
import RootStore from "../RootStore/RootStore";
import { UserListDataProps } from "./MetricsInterface";

export type addData = {
	name: string;
	type: number;
	format_of_matrix?: number;
	expression?: string;
	metric_category_id?: number;
	expression_readable?: string;
	expression_data?: string;
	expression_ids?: number[];
};

export type metricDetails = {
	id: number;
	name: string;
	type: number;
	metric_category_id: number;
	format_of_matrix: number;
	expression: string;
	expression_ids: number[];
	is_active: 1 | 0;
	created_by: string;
	updated_by: string;
	deleted_by: string;
	deleted_at: string;
	created_at: string;
	updated_at: string;
	category: string;
};

export default class MetricStore {
	public list_data?: UserListDataProps[];
	private rootStore: RootStore;
	public grid?: any;
	public metricDetails?: metricDetails;
	public current_page?: number;
	private commonStore: CommonStore;

	public fields: any[] = [];
	public readable_format = "";
	public formula_status = "success";
	public custom_error_msg = "Parse Success";
	public invInput: any;

	constructor(commonStore: CommonStore) {
		this.invInput = "";
		this.commonStore = commonStore;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.list_data = value;
	};

	public setMetricDetails = (value?: any) => {
		this.metricDetails = value;
	};

	private setGrid = (value?: any) => {
		this.grid = value;
	};

	// Grid Setup Functions

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
	};

	public changeFilterAndSort = (params: any) => {
		if (params.filterModel && params.filterModel["is_active"]) {
			params.filterModel["is_active"].values = convertTextToID(
				params.filterModel["is_active"],
				this.commonStore.statusFilter
			);
		}
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				this.changeFilterAndSort(params.request);
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = async (payload?: any): Promise<any> => {
		return await axios.post(API_URL.METRICS.LIST, payload).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public fetchMetricDetails = async (id: number): Promise<any> => {
		return await axios.get(API_URL.METRICS.DETAILS(id)).then(({ data }) => {
			this.setMetricDetails(data.data);
		});
	};

	public addMetric = async (data: addData) => { 
		return await axios.post(API_URL.METRICS.CREATE, data).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public updateMetric = async (id: number, data: addData) => {
		return await axios.post(API_URL.METRICS.UPDATE(id), data).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public deleteMetric = async (id: number) => {
		return await axios.delete(API_URL.METRICS.DELETE(id)).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public changeStatus = async (id: number, is_active: number) => {
		return await axios
			.post(API_URL.METRICS.CHANGE_STATUS(id), { is_active })
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};

	getParsedReadableFormula = (formula: any): any => {
		if (formula.operator) {
			if (
				formula.operand1.value &&
				formula.operand1.value.unit === 0 &&
				formula.operator === "-"
			) {
				return `( ${formula.operator} ${this.getParsedReadableFormula(
					formula.operand2
				)} )`;
			}
			return `( ${this.getParsedReadableFormula(formula.operand1)} ${
				formula.operator
			} ${this.getParsedReadableFormula(formula.operand2)} )`;
		} else if (formula.value) {
			if (formula.value.type === "unit") {
				return formula.value.unit;
			} else {
				if (formula.value.type === "item") {
					return formula.value.item.text;
					// if (!this.fields.includes(formula.value.item.text)) {
					// 	this.fields.push(formula.value.item.text);
					// }
					// if (this.list_data) {
					// 	const field = this.list_data.find(
					// 		(x) => x.id === formula.value.item.text
					// 	);
					// 	if (field) {
					// 		return `'${field.name}'`;
					// 	}
					// }
					// this.formula_status = "error";
					// return "";
				}
			}
		}
		return "";
	};
}
