import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { UserListDataProps } from "./RefferalsInterface";

export default class ReferralsStore {
	public listData?: UserListDataProps[];
	public grid?: any;
	private rootStore: RootStore;
	public details?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.listData = value;
	};

	private setGrid = (value?: any) => {
		this.grid = value;
	};

	public setDetails = (details?: any) => {
		this.details = details;
	};

	// Grid Setup Functions

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.success({ rowData: data.rows, rowCount: data.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = async (payload?: any): Promise<any> => {
		return await axios
			.post(API_URL.REFFERALS.LIST, payload)
			.then(({ data }) => {
				this.setListData(data.data.count);
				return data.data;
			});
	};

	public deleteReferrals = async (id: number) => {
		return await axios.delete(API_URL.REFFERALS.DELETE(id)).then(() => {
			this.setupGrid(this.grid);
			this.setDetails(undefined);
		}).catch(() => {
			this.setDetails(undefined);
		});
	};

	public updateReferrals = async (id:number,is_referred:number):Promise<any> => {
		return await axios.post(API_URL.REFFERALS.ACCEPT_REFERRAL,{id,is_referred}).then(({data})=>{
			this.setupGrid(this.grid)
			return data
		})
	}

	public changeStatusReferral = async(id:number,is_active:number)=>{
		return await axios.post(API_URL.REFFERALS.CHANGE_STATUS(id,is_active)).then((data)=>{
			this.setupGrid(this.grid)
			return data
		})
	}
}
