import React from "react";
import { useRoutes } from "react-router-dom";
import RecoveryPassword from "../views/app_pages/auth/RecoveryPassword";
import Login from "../views/app_pages/auth/Login";
import ResetPasswordViaLink from "../views/app_pages/auth/ResetPasswordViaLink";
import AuthLayout from "../views/layouts/AuthLayout";
import MainLayout from "../views/layouts/MainLayout";

const Dashboard = React.lazy(() => import("../views/app_pages/Dashboard"));
const UserView = React.lazy(() => import("../views/app_pages/UserManagement"));
const Metrics = React.lazy(
	() => import("../views/app_pages/MetricManagement/Metrics")
);

const Subscription = React.lazy(
	() => import("../views/app_pages/Subscription")
);
const Referrals = React.lazy(() => import("../views/app_pages/Referrals"));
const Clients = React.lazy(() => import("../views/app_pages/Clients"));
const ClientUsers = React.lazy(() => import("../views/app_pages/Clients/Components/ClientUsers"));
const MyTeamMembers = React.lazy(
	() => import("../views/app_pages/MyTeamMembers/index")
);
const MetricCategory = React.lazy(
	() => import("../views/app_pages/MetricManagement/MetricCategory")
);
const Roles = React.lazy(() => import("../views/app_pages/Roles"));
const Templates = React.lazy(() => import("../views/app_pages/Templates"));
const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));
const AllTransaction = React.lazy(
	() => import("../views/app_pages/AllTransaction")
);
const ReferralUser = React.lazy(() => import("../views/app_pages/Referrals/Components/ReferralUser"));

export default function Router() {
	const element = useRoutes(RouterConfig);
	return element;
}

export const RouterConfig = [
	{
		element: <AuthLayout />,
		children: [
			{
				path: "/login",
				element: <Login />,
			},
			{
				path: "/unauthorized",
				element: <Unauthorized />,
			},
			{
				path: "/recover-password",
				element: <RecoveryPassword />,
			},
			{
				path: "/reset-password/:otp",
				element: <ResetPasswordViaLink />,
			},
		],
	},
	{
		element: <MainLayout />,
		children: [
			{
				path: "/",
				element: <Dashboard />,
			},
			{
				path: "/dashboard",
				element: <Dashboard />,
			},
			{
				path: "/user-management",
				element: <UserView />,
			},
			{
				path: "/transaction-models", // for server side grid example
				element: <AllTransaction />,
			},
			{
				path: "/metrics",
				element: <Metrics />,
			},
			{
				path: "/metric-box",
				element: <Metrics />,
			},
			{
				path: "/subscription",
				element: <Subscription />,
			},
			{
				path: "/referrals",
				element: <Referrals />,
			},
			{
				path: "/client-management",
				element: <Clients />,
			},
			{
				path: "/client-management/:companyId/users",
				element: <ClientUsers />,
			},
			{
				path: "/team-member",
				element: <MyTeamMembers />,
			},
			{
				path: "/metric-categories",
				element: <MetricCategory />,
			},
			{
				path: "/roles",
				element: <Roles />,
			},
			{
				path: "/template",
				element: <Templates />,
			},
			{
				path:"/referral-user",
				element: <ReferralUser/>
			}
		],
	},
	{
		path: "*",
		element: <PageNotFound />,
	},
];
