import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { convertTextToID } from "../../config/Global";
import CommonStore from "../CommonStore/CommonStore";
import RootStore from "../RootStore/RootStore";

export default class MetricGroupStore {
	public metricGroupList?: any;
	public metricGroupDetails?: any;
	public grid?: any;
	private rootStore: RootStore;
	private commonStore: CommonStore;

	constructor(commonStore: CommonStore) {
		this.commonStore = commonStore;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter function
	public setList = (list: any) => {
		this.metricGroupList = list;
	};

	public setMetricGroupDetails = (detail?: any) => {
		this.metricGroupDetails = detail;
	};

	private setGrid = (value?: any) => {
		this.grid = value;
	};

	// Grid Setup Functions

	public changeFilterAndSort = (params: any) => {
		// Filter
		if (params.filterModel && params.filterModel["is_active"]) {
			params.filterModel["is_active"].values = convertTextToID(
				params.filterModel["is_active"],
				this.commonStore.statusFilter
			);
		}
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				this.changeFilterAndSort(params.request);
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchMetricGroupList(payload).then((data: any) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API functions
	public fetchMetricGroupList = async (payload?: any) => {
		return await axios
			.post(API_URL.METRICS_GROUP.LIST, payload)
			.then(({ data }) => {
				this.setList(data.data.rows);
				return data.data;
			});
	};

	public fetchMetricGroupMetricList = async (id: number, payload?: any) => {
		return await axios
			.post(API_URL.METRICS_GROUP.METRIC_LIST(id), payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public fetchMetricGroupDetails = async (id: number) => {
		return await axios
			.get(API_URL.METRICS_GROUP.DETAILS(id))
			.then(({ data }) => {
				this.setMetricGroupDetails(data.data);
			});
	};

	public addMetricGroup = async (data: any) => {
		return await axios.post(API_URL.METRICS_GROUP.CREATE, data).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public updateMetricGroup = async (data: any, id: number) => {
		return await axios.post(API_URL.METRICS_GROUP.UPDATE(id), data).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public deleteMetricGroup = async (id: number) => {
		return await axios.delete(API_URL.METRICS_GROUP.DELETE(id)).then(() => {
			return this.setupGrid(this.grid);
		});
	};

	public updateMetricCategory = async (data: any, id: number) => {
		return await axios
			.post(API_URL.METRICS_GROUP.CHANGE_CATEGORY(data, id))
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};

	public changeStatus = async (id: number, is_active: number) => {
		return await axios
			.post(API_URL.METRICS_GROUP.CHANGE_STATUS(id), {
				is_active,
			})
			.then(() => {
				return this.setupGrid(this.grid);
			});
	};

	public addMetricToGroup = async (
		metric_id: number[],
		metric_group_id: number
	) => {
		return await axios
			.post(API_URL.METRICS_GROUP.ADD, {
				metric_id,
				metric_group_id,
			})
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data;
			});
	};

	public removeMetricFromGroup = async (
		metric_id: number[],
		metric_group_id: number
	) => {
		return await axios
			.post(API_URL.METRICS_GROUP.REMOVE, {
				metric_id,
				metric_group_id,
			})
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data;
			});
	};

}
