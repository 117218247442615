import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import Router from "./config/RouterConfig";
import useStore from "./store";

const App: React.FC = observer(() => {
	const {
		AUTH: { InitializeApp, app_loading },
		COMMON: { fetchSync },
	} = useStore();

	useEffect(() => {
		InitializeApp();
		fetchSync();
	}, []);
	return (
		<BrowserRouter>{app_loading ? <PageSpinner /> : <Router />}</BrowserRouter>
	);
});

export default App;
